@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
	font-family: "Roboto" , sans-serif;
	height: 100vh;
	background: #CD7430;
	padding: 1em;
	overflow: hidden;
}

.background-wrapper {
    z-index: -1;
	position: relative;
	width: 100%;
	height: 100%;
	user-select: none;
	h1 {
        z-index: -2;

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-45deg);
		font-family: "Roboto" , sans-serif;
		font-size: 30vmax;
		color: #ee8839;
		letter-spacing: 0.025em;
		margin: 0;
		transition: 750ms ease-in-out;
	}
}

a {
	border: 2px solid #555;
	padding: 0.5em 0.8em;
	position: fixed;
	z-index: 3;
	color: #555;
	text-decoration: none;
	display: flex;
	align-items: center;
	transition: 150ms;
	svg > polyline {
		transition: 150ms;
	}
	&:hover {
		color: #333;
		background: #dadada;
		border-color:transparent;
		svg > polyline {
			stroke: #000;
		}
		+ .background-wrapper > h1 {
			color: #dadada;
		}
	}
}
@media screen and (min-width: 901px) {
p {
    z-index: 3;
	color: #dadada;
	font-size: calc(1em + 3vmin);
	position: fixed;
	bottom: 1rem;
	right: 1.5rem;
	margin: 0;
	text-align: right;
	
	@media screen and (min-width: 940px) {
		width: 40%;
	}
	@media screen and (min-width: 1300px) {
		width: 35%;
	}
}
}
@media screen and (max-width: 900px) {
    
    p {
        width:70%;
        z-index: 3;
        color: #dadada;
        font-size: calc(1em + 6vmin);
        position: absolute;
        top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
        margin: 0;
        text-align: center;
    }
}